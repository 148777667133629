/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { NumericInputComponent } from '../../core/numeric-input/numeric-input.component';
import { BaseFormFieldComponent } from '../base-form-field.component';
import { FormFieldWrapperComponent } from '../form-field-wrapper.component';

@Component({
  selector: 'do-numeric-field',
  template: `
    <do-form-field-wrapper
      [label]="label"
      [tooltip]="tooltip"
      [shouldShowError]="shouldShowError()"
      [errors]="errors()"
      [showHint]="showHint"
    >
      <!-- tolto prefisso € + allineamento dx -->
      <do-numeric-input
        [hasError]="shouldShowError()"
        [formControl]="formControl"
        (blur)="onBlur()"
        [digits]="digits"
        [min]="min"
        [max]="max"
        [digitsOptional]="isCurrency ? false : true"
        [nullable]="isCurrency ? false : nullable"
        [showGroupSeparator]="showGroupSeparator"
        [readOnly]="readOnly"
        [rightAlign]="rightAlign"
      >
      </do-numeric-input>
      <!-- <do-numeric-input
        [hasError]="shouldShowError()"
        [formControl]="formControl"
        (blur)="onBlur()"
        [digits]="digits"
        [min]="min"
        [max]="max"
        [prefix]="isCurrency ? '€ ' : ''"
        [digitsOptional]="isCurrency ? false : true"
        [nullable]="isCurrency ? false : nullable"
        [showGroupSeparator]="showGroupSeparator"
        [readOnly]="readOnly"
      >
      </do-numeric-input> -->
    </do-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormFieldWrapperComponent,
    ReactiveFormsModule,
    NumericInputComponent,
  ],
})
export class NumericFieldComponent extends BaseFormFieldComponent {
  @Input()
  digits: number | '*' = 2;

  @Input()
  min?: number;

  @Input()
  max?: number;

  @Input()
  isCurrency = false;

  @Input()
  nullable = false;

  @Input()
  rightAlign = true;

  _value: any = undefined;
  @Input()
  public set value(v: any) {
    this._value = v;
    this.formControl.setValue(v, { emitEvent: false });
  }

  @Input()
  showGroupSeparator = true;

  @Output()
  changed = new EventEmitter<any>();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  onBlur() {
    this.blur.emit();
  }
  override onChanged(v: any) {
    super.onChanged(v);
    this.changed.emit(v);
  }
}
