/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

let nextId = 0;
@Component({
  selector: 'do-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input()
  value = false;

  @Input()
  alignRight = false;

  @Input()
  isDisabled = false;

  id = `sw-${nextId++}`;

  @Input()
  hasError = false;

  @Input()
  label!: string;

  @Output()
  checked = new EventEmitter<boolean>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (value: boolean) => {};
  validateFn: any = () => {};
  onTouched = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  writeValue(obj: any): void {
    this.value = obj;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: boolean) => void) {
    this.propagateChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(control: FormControl) {
    const res = this.validateFn(control);
    if (res) this.hasError = true;
    return res;
  }

  // ngOnInit(): void {
  //   if (this.label == undefined) {
  //     throw console.error('label undefined');
  //   }
  // }

  onModelChange() {
    this.propagateChange(this.value);
    this.onTouched();
    this.checked.emit(this.value);
  }
}
