import { EventEmitter, Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  showSidebar = signal(true);

  animationCompleted = new EventEmitter<void>();

  toggleSidebar() {
    this.showSidebar.update((v) => !v);
  }

  hideSidebar() {
    this.showSidebar.set(false);
  }
}
