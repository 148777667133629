<div class="form-check form-switch">
  <label class="form-check-label" for="do-sw-{{ id }}">{{ label }}</label>
  <input
    class="form-check-input"
    [class.ms-auto]="alignRight"
    type="checkbox"
    role="switch"
    [disabled]="isDisabled"
    id="do-sw-{{ id }}"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange()"
  />
</div>
