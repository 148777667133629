/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { SwitchComponent } from '../../core/switch/switch.component';
import { BaseFormFieldComponent } from '../base-form-field.component';
import { FormFieldWrapperComponent } from '../form-field-wrapper.component';

@Component({
  selector: 'do-switch-field',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }
    `,
  ],
  template: `
    <do-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError()"
      [errors]="errors()"
      [tooltip]="tooltip"
    >
      <do-switch [hasError]="shouldShowError()" [formControl]="formControl">
      </do-switch>
    </do-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormFieldWrapperComponent, ReactiveFormsModule, SwitchComponent],
})
export class SwitchFieldComponent extends BaseFormFieldComponent {}
